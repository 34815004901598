<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="color"
  >
    <g transform="translate(1 1)">
      <path
        class="a"
        d="M704.647,438.13l-1-.216a8.965,8.965,0,0,0-.857-2.051l.563-.869a1.663,1.663,0,0,0-.3-2.058l-.763-.763a1.662,1.662,0,0,0-2.057-.3l-.88.569a9.123,9.123,0,0,0-2.035-.836l-.22-1.031a1.662,1.662,0,0,0-1.665-1.247h-1.079a1.665,1.665,0,0,0-1.665,1.247l-.22,1.031a9.131,9.131,0,0,0-2.035.836l-.88-.569a1.663,1.663,0,0,0-2.058.3l-.763.763a1.66,1.66,0,0,0-.295,2.058l.561.869a9.124,9.124,0,0,0-.857,2.051l-1,.216a1.66,1.66,0,0,0-1.245,1.663v1.081a1.66,1.66,0,0,0,1.245,1.663l.984.211a9.017,9.017,0,0,0,.855,2.092l-.54.834a1.658,1.658,0,0,0,.295,2.056l.763.765a1.662,1.662,0,0,0,2.058.294l.823-.531a9.009,9.009,0,0,0,2.108.875l.2.956a1.664,1.664,0,0,0,1.665,1.245h1.079a1.661,1.661,0,0,0,1.665-1.245l.2-.956a9,9,0,0,0,2.11-.875l.821.531a1.662,1.662,0,0,0,2.057-.294l.763-.765a1.661,1.661,0,0,0,.3-2.056l-.539-.834a9,9,0,0,0,.854-2.092l.984-.211a1.661,1.661,0,0,0,1.247-1.663v-1.081A1.661,1.661,0,0,0,704.647,438.13Z"
        transform="translate(-683.894 -429.333)"
      />
      <g class="a" transform="translate(6 6)">
        <circle class="b" cx="5" cy="5" r="5" />
        <circle class="c" cx="5" cy="5" r="4" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Settings",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.strom {
  .a {
    stroke: $strom;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }
}
.a,
.c {
  fill: none;
}
.a {
  transition: all ease 0.3s;
  stroke: #2d323c;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.b {
  stroke: none;
}
</style>