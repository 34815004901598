<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24"
    viewBox="0 0 18 24"
  >
    <path
      class="a"
      d="M16.222,1.756a6.048,6.048,0,0,0-8.522,0L4.858,4.581A5.99,5.99,0,0,0,9.119,14.809a6.054,6.054,0,0,0,2.572-.6A2.826,2.826,0,0,0,10.358,12.6a4.028,4.028,0,0,1-4.08-.957,3.986,3.986,0,0,1,0-5.651L9.119,3.169a4.032,4.032,0,0,1,5.682,0,3.982,3.982,0,0,1,0,5.651l-1.213,1.207c.078.072.161.139.237.215a7,7,0,0,1,1.045,1.336l1.352-1.345a5.971,5.971,0,0,0,0-8.476Z"
      transform="translate(0.014 0)"
    />
    <path
      class="a"
      d="M14.8,14.167a5.934,5.934,0,0,0-1.671-3.21,6.006,6.006,0,0,0-1.1-.861h0a5.982,5.982,0,0,0-1.5-.661l-.039-.01A6.092,6.092,0,0,0,9.8,9.28c-.058-.009-.116-.019-.174-.026a6.2,6.2,0,0,0-.76-.053,5.931,5.931,0,0,0-1.017.1c-.067.011-.135.021-.2.035a6,6,0,0,0-.918.266c-.025.01-.053.013-.078.023l.005.008c-.152.06-.3.119-.451.191a2.958,2.958,0,0,0,.7,1.128,3,3,0,0,0,.66.481c.087-.03.179-.046.268-.071.129-.036.257-.075.39-.1a3.7,3.7,0,0,1,.642-.06,4.03,4.03,0,0,1,2.84,1.171,3.981,3.981,0,0,1,0,5.65l-2.84,2.826a4.04,4.04,0,0,1-5.683,0,3.982,3.982,0,0,1,0-5.651l1.17-1.163c-.1-.086-.195-.167-.287-.259A6.985,6.985,0,0,1,3.06,12.494l-1.3,1.288a5.971,5.971,0,0,0,0,8.476,6.059,6.059,0,0,0,8.523,0l2.84-2.826a5.934,5.934,0,0,0,1.765-4.237,6.042,6.042,0,0,0-.082-.957C14.807,14.214,14.8,14.191,14.8,14.167Z"
      transform="translate(0 -0.01)"
    />
  </svg>
</template>

<script>
export default {
  name: "CopyLink",
};
</script>

<style lang="scss" scoped>
.a {
  fill: $midnight;
}
</style>